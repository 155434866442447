export const eventInputFields = [
  { id: "name", title: "Event Type (e.g. SBK social)", isMajor: true },
  { id: "isRecurring", title: "Is this a weekly event?", isMajor: false },
  { id: "isMajor", title: "Is this large, multi-day event?", isMajor: true },
  { id: "hosts", title: "Host(s)", isMajor: false },
  { id: "venue", title: "Venue Name", isMajor: true },
  { id: "musicProvider", title: "Music by", isMajor: false },
  { id: "coverCharge", title: "Cover Charge? (K IDR)", isMajor: false },
  { id: "city", title: "Event Location", isMajor: true },
  {
    id: "locationLink",
    title: "Location Link (Google Maps URL)",
    isMajor: true,
  },
  // { id: "promos", title: "Promos" },
  { id: "startTime", title: "Start Time and Date", isMajor: true },
  { id: "endTime", title: "End Time", isMajor: true },
  { id: "description", title: "Event Description", isMajor: true },
  { id: "eventImage", title: "Event Image", isMajor: true },
  // { id: "status", title: "Cancel this week?", isMajor: false },
  // {id: 'className', title: 'Class Name'},
  // {id: 'classStyle', title: 'Class Style'},
  // {id: 'classCost', title: 'Class Cost'},
  // {id: 'classLevel', title: 'Class Level'},
];

// If I remove a name, that container won't render. Not breaking but not backwards compatible.
// This will be a user-customizable order in the future.
export const cityLocations = [
  "Seminyak",
  "Canggu",
  "Ubud",
  "Sanur",
  "Umalas",
  "Kuta",
  "Denpasar",
  "Uluwatu",
  "Other",
];

// For filling Dummy Events
export const NEW_EVENT_ONE = {
  name: "SBK Social",
  city: "Seminyak",
  venue: "Warung Italia",
  locationLink: "https://goo.gl/maps/4zCRNrZgtJNkUFER8",
  startTime: new Date(),
  endTime: new Date(),
  hosts: "Ryan",
  coverCharge: 50,
  // promos: "you wish bozo",
  // currency: "IDR",
  // styles: "SBK",
  musicProvider: "2 Chainz",
  eventImageUrl:
    "https://res-1.cloudinary.com/dtwsfx5bo/image/upload/qwuo4inbsua3a9zdtigjbmpw417a.jpeg",
  description: `Todos los martes después de clase tenemos una jam after class para practicar lo aprendido o simplemente disfrutar. Apelamos a esa manera en la que nació este baile; a ir después de trabajar con lo puesto a bailar solo por el disfrute.
  ⏰ 21h
  📍Satchmo (C/Cabo Jubi,4)
  🎟 Entrada libre y gratuita
  Puedes venir tanto si vienes a clases a la escuela como si no.
  Además, puedes hacer parada técnica en nuestro Club Manhattan. ¡Ay! todo lo que sucede en la barra de bar...
  ¡Te esperamos cada martes!
  Keep swinging!`,
  // hasClass: false,
};

export const NEW_EVENT_TWO = {
  name: "SBK Social",
  city: "Kuta",
  venue: "Skygarden",
  locationLink: "https://goo.gl/maps/4zCRNrZgtJNkUFER8",
  startTime: new Date(),
  endTime: new Date(),
  hosts: "Buzz Lightyear",
  coverCharge: 0,
  // promos: "you wish bozo",
  // currency: "IDR",
  // styles: "Zouk",
  musicProvider: "J Timbs",
  description: "He is a bachata boy now.",
  eventImageUrl:
    "https://res-1.cloudinary.com/dtwsfx5bo/image/upload/qwuo4inbsua3a9zdtigjbmpw417a.jpeg",
  // hasClass: false,
};

export const TERMS_TEXT = [
  {
    title: "Terms and Conditions of Use",
    content: `This document sets out the terms and conditions of use of the danceinbali.com website. By accessing and using this website, you agree to these terms and conditions in their entirety. If you do not agree with any of these terms, please do not use this website.

1) Use of the website
The danceinbali.com website is a platform for searching for salsa, batchata, and other dance events in your city. The use of this website is subject to the following conditions:

  a) You must use the website responsibly and not misuse its services.

  b) You must provide accurate and up-to-date information for event searching.

  c) You must not attempt to access unauthorized parts of the website or use malicious software to damage the website.

2) Intellectual property
The danceinbali.com website and all of its content, including text, images, logos, graphics, and software, are the exclusive property of danceinbali.com and are protected by applicable intellectual property laws. The use of any of this content without express permission from danceinbali.com is prohibited.

3) Privacy policy
The privacy policy of danceinbali.com is contained in a separate document, which forms an integral part of these terms and conditions.

4) Modifications to terms and conditions
danceinbali.com reserves the right to modify these terms and conditions at any time. If changes are made, the updated version of the terms and conditions will be published on the website. If you continue to use the website after the publication of the updated terms and conditions, it will be considered that you accept these changes.

5) Events on the website
The events shown on danceinbali.com are extracted from other websites and/or groups. danceinbali.com is not responsible for the accuracy, quality, or availability of the events shown on its website.

6) Limitation of liability
The danceinbali.com website is provided "as is" and "as available." To the maximum extent permitted by applicable law, danceinbali.com disclaims any warranties, express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement. danceinbali.com does not guarantee that the website is free of errors or that its access is uninterrupted. In no event shall danceinbali.com be liable for any direct, indirect, incidental, special, punitive, or consequential damages arising from the use or inability to use the danceinbali.com website.

7) Governing law
These terms and conditions are governed by the laws of the European Union, and any dispute related to these terms and conditions will be resolved by the competent courts of that jurisdiction.

By using the danceinbali.com website, you accept these terms and conditions. If you have any questions or comments about these terms and conditions, please contact us through our website.
`,
  },
];

export const PRIVACY_TEXT = [
  {
    title: "Privacy Policy",
    content: `The privacy of our users is important to us. This privacy policy describes how personal information is collected, used, and protected when using the danceinbali.com website. By using the website, you agree to the terms and conditions of this privacy policy.

1) Personal Information Collected
danceinbali.com collects personal information from users when registering to use the website's services, such as name and email address. We may also collect information through first-party browser cookies, which allow the website to remember your visit and preferences.

2) Use of Personal Information
The personal information collected is used to provide the services offered by danceinbali.com, to improve the website, and to communicate with users about updates and new events. We do not share or sell personal information to third parties. We do not use personal information for any adversiting or promotional purposes.

3) Security of Personal Information
danceinbali.com is committed to protecting user's personal information and uses appropriate security measures to prevent unauthorized access or misuse of personal information.

4) Modification of Personal Information
Users can modify their personal information at any time by logging into the website and editing their profile. Users can also request the removal of their account and personal information by contacting the danceinbali.com support team.

5) Cookies
The danceinbali.com website uses first-party cookies to enhance the user experience. Cookies are small text files that are stored on the user's device to remember information and preferences.

6) External Links
The danceinbali.com website may contain links to other websites. We are not responsible for the privacy practices or content of those websites.

7) Changes to Privacy Policy
danceinbali.com reserves the right to modify this privacy policy at any time. If changes are made, the updated version of the privacy policy will be published on the website. If you continue to use the website after the publication of the updated privacy policy, it will be considered that you accept those changes.
`,
  },
];

export const ABOUT_TEXT = [
  //   {
  //     title: "About Latin Dance Bali",
  //     content: `Hello dancers! Welcome to Latin Dance Bali, your go-to hub for, well, Latin dance in Bali! We understand the excitement of immersing yourself in the vibrant rhythms of salsa, bachata, kizomba, and zouk, but keeping track these events can be challenging. That's why we've dedicated this space to bring the rhythm of Bali's Latin dance scene right to your fingertips.

  // At Latin Dance Bali, our mission is to make your dance journey seamless and enjoyable. Whether you're a seasoned dancer or just stepping onto the dance floor for the first time, Bali Latin Dance has something for you. Whether you're after lively classes, social dances, live music, or special workshops, we've got your back.  No more hopping between different sites – find all your dance event information conveniently in one place.

  // Join us as we bring the Bali Latin dance community together, creating a central space where anyone can discover and connect with the rhythm of the island.

  // This site is currently in beta and is actively being developed. If you have any questions, comments, suggestions about future features, please reach out and contact us.

  // Thank you for being part of the community. Let's dance!

  // `,
  //   },
  {
    title: "About Latin Dance Bali",
    content: `Halo everyone! As you know, there are many sources of information about the Latin dance scene here in Bali. I built Latin Dance Bali in order to connect dancers, hosts, event organizers, visitors, teachers, and students under one roof which everyone can access. Dancers can see what's on for the upcoming week, students can find teachers and classes, and event organizers can share multi-day festivals and workshops. 

I welcome your suggestions for new features and improvements for this site. Hit me up via the "Contact" page. As the community grows larger and stronger, we can update this tool to suit our needs exactly. Let's enjoy the ride and build something awesome!
`,
  },
  {
    title: "For Hosts & Organizers",
    content: `This site is currently built only to serve events hosted in Bali and is 100% FREE. Local hosts may post as many events as they wish, include any relevant information, and update their postings whenever they want. Hosts and organizers can also have their own unique profiles, including socials and contact information. 

In order to post an event, an organizer must create an account. Click the “Post Event” button in the hamburger menu, enter your info, and click submit. I review all requests to ensure they are legitimate, usually within 1 day submission. Once you are approved, you can post as much as you want!
`,
  },
  {
    title: "For Everyone Else",
    content: `Go crazy. Check out every event in Bali, then go dance at them. No account necessary. Share with your friends, and then your 17 pet cats. See you on the dance floor!`,
  },
  {
    title: "Disclaimer",
    content: `We are just getting started. The site isn't perfect, and I cannot personally verify all the information presented here as schedules can change pretty rapidly here in Bali. I am not providing 100% of this information as I'm working with organizers to keep their event information as current as possible. Please be patient :)`,
  },
  // {
  //   title: 'Disclaimer 2',
  //   content: `This site is 100% free to use for dancers and local event organizers. I have not been paid to develop this site, and I am paying the server costs myself for the benefit of the community. Latin Dance Bali collects a bare minimum of information of organizers for sole use within the community, and does not track any visitor on this site. Any information is not sold to any 3rd  party under any condition, and it never will be. `
  // }
];
