import { TERMS_TEXT, PRIVACY_TEXT, ABOUT_TEXT } from "../../../utils/Constants";
import { useOutletContext } from "react-router-dom";

export default function TermsView(props) {
  const { isMobile } = useOutletContext();

  const textBlockHash = {
    terms: TERMS_TEXT,
    privacy: PRIVACY_TEXT,
    about: ABOUT_TEXT,
  };

  const usedBlock = textBlockHash[props.textBlock];
  // TODO: should I make mobile (or both) collapsable? maybe when it becomes more important
  return (
    <div style={isMobile ? styles.mobileContainer : styles.container}>
      {usedBlock.map((section) => (
        <div key={section.title}>
          <h2>{section.title}</h2>
          <p>{section.content}</p>
        </div>
      ))}
    </div>
  );
}

const styles = {
  container: {
    whiteSpace: "pre-wrap",
    width: "50%",
    margin: "20px auto 0px",
  },
  mobileContainer: {
    whiteSpace: "pre-wrap",
    padding: "32px 32px 12px",
  },
};
