import React from "react";
import LinkInternalView from "../../atoms/LinkInternalView/LinkInternalView";
import { blackColor } from "../../../utils/globalStyles";

export default function FooterView(props) {
  const { isMobile } = props;

  const linkText = {
    about: "About",
    terms: isMobile ? "T's & C's" : "Terms & Conditions ",
    contact: "Contact",
    privacy: isMobile ? "Privacy" : "Privacy Policy",
  };

  const dynamicLinkContainer = {
    ...styles.linkContainer,
    padding: isMobile ? "0px 8px" : "0px 16px",
  };

  return (
    <div style={styles.footer}>
      <div style={dynamicLinkContainer}>
        <LinkInternalView text={linkText.about} to="/about" sx={styles.link} />
        <LinkInternalView
          text={linkText.contact}
          to="/contact"
          sx={styles.link}
        />
        <LinkInternalView text={linkText.terms} to="/terms" sx={styles.link} />
        <LinkInternalView
          text={linkText.privacy}
          to="/privacy"
          sx={styles.link}
        />
      </div>
    </div>
  );
}

const styles = {
  footer: {
    display: "flex",
    alignItems: "center",
    background: blackColor,
    minHeight: 40,
  },
  linkContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  link: {
    ml: 2,
  },
};
