// super copy/pasted from https://frontendshape.com/post/react-mui-5-contact-us-page-example
import { useState } from "react";
import { TextField, Button, Typography, Box } from "@mui/material";
import { sendContactEmail } from "../../../features/users/usersSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { muiColors } from "../../../utils/globalStyles";

export default function ContactUsView() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSending, setIsSending] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);
    try {
      const messageDetails = { name, email, message };
      const response = await dispatch(sendContactEmail(messageDetails));
      if (response.payload.result === "success") {
        // Handle successful email submission
        alert("Message sent successfully!");
        setName("");
        setEmail("");
        setMessage("");
        // add navigation here?
      } else {
        // Handle error in email submission
        alert("Failed to send message.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setIsSending(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 4,
      }}
    >
      <Box sx={{ maxWidth: 600, mx: "auto", p: 2 }}>
        <Typography variant="h4" align="center" mb={2}>
          Contact
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            required
            type="email"
          />
          <TextField
            fullWidth
            label="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            margin="normal"
            required
            multiline
            rows={4}
          />
          <div style={{ display: "flex", alignItems: "center", marginTop: 16 }}>
            <Button
              variant="contained"
              type="submit"
              sx={{ mr: 2 }}
              disabled={isSending}
              color={muiColors.paletteRed}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              sx={{ mr: 2 }}
              disabled={isSending}
              color={muiColors.black}
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
            {isSending && <div>Sending...</div>}
          </div>
        </form>
      </Box>
    </Box>
  );
}
