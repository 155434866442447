import React, { useState } from "react";
import ButtonView from "../../atoms/ButtonView/ButtonView";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../../../features/users/usersSlice";
import LinkInternalView from "../../atoms/LinkInternalView/LinkInternalView";
import LabelView from "../../atoms/LabelView/LabelView";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { selectIsAdmin, selectIsHost } from "../../../utils/UserPermissions";
import qrImage from "../../../resources/images/qrcode.png"; // got qr code from https://www.the-qrcode-generator.com/
import { whiteColor, paletteRed, muiColors } from "../../../utils/globalStyles";

export default function HeaderView(props) {
  const { isMobile } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [isQrCodeOpen, setIsQrCodeOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.users.isLoggedIn);
  const isHost = useSelector(selectIsHost);
  const isAdmin = useSelector(selectIsAdmin);

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const onSelect = (path) => {
    if (path === "logout") {
      dispatch(logoutUser());
      navigate("/");
    } else {
      navigate(path);
    }
    handleMenuClose();
  };

  const logoButton = (
    <div onClick={() => navigate("/")}>
      <LabelView
        text="Latin Dance Bali"
        // type="dashley"
        type="permanentMarker"
        style={{ ...styles.logoButton, fontSize: isMobile ? 24 : 28 }}
      />
    </div>
  );

  const betaTag = (
    <LabelView
      text="BETA"
      style={{
        marginLeft: 2,
        marginTop: isMobile ? 3 : 6,
        color: whiteColor,
        fontSize: isMobile ? 12 : 14,
      }}
    />
  );

  const loginButtons = (
    <>
      <LinkInternalView text="Post Event" sx={{ ml: 2 }} to="/signup" />
      <LinkInternalView id="login-link" text="Log In" to="/login" />
    </>
  );

  const logoutButton = (
    <LinkInternalView
      // variant="contained"
      text="Log Out"
      sx={{ ml: 3 }}
      to="/"
      onClick={() => dispatch(logoutUser())}
    />
  );

  const hamburgerMenu = (
    <div onClick={handleMenuOpen} style={styles.hamburgerContainer}>
      <>
        <div style={styles.hamburgerLine} />
        <div style={styles.hamburgerLine} />
        <div style={styles.hamburgerLine} />
      </>
    </div>
  );

  const qrModal = (
    <div className="modal" onClick={() => setIsQrCodeOpen(false)}>
      <img src={qrImage} alt="qr_code" style={{ height: 300 }} />
    </div>
  );

  const header = (
    <div style={styles.headerContainer}>
      <div style={{ display: "flex" }}>
        {logoButton}
        {betaTag}
      </div>

      <div>
        {isAdmin && (
          <ButtonView
            variant="contained"
            text="Admin"
            to="/admin"
            color={muiColors.black}
          />
        )}
        {isLoggedIn && (isHost || isAdmin) && (
          <>
            <LinkInternalView
              text="Create Event"
              sx={{ ml: 3 }}
              to="/createEvent"
            />
            <LinkInternalView text="My Events" to="/myEvents" sx={{ ml: 3 }} />
            <LinkInternalView text="Settings" to="/settings" sx={{ ml: 3 }} />
          </>
        )}
        <LinkInternalView text="Community" to="/hostsOverview" sx={{ ml: 3 }} />
        <LinkInternalView
          text="Special Events"
          to="/specialEvents"
          sx={{ ml: 3 }}
        />

        {isLoggedIn ? logoutButton : loginButtons}
      </div>
    </div>
  );

  function getMobilePaths() {
    let loggedInPaths = [
      { path: "createEvent", title: "Create Event" },
      { path: "myEvents", title: "My Events" },
      { path: "settings", title: "Settings" },
      { path: "logout", title: "Log Out" },
    ];

    if (isAdmin) {
      loggedInPaths.push({ path: "admin", title: "Admin" });
    }

    let loggedOutPaths = [
      { path: "signup", title: "Post Event" },
      { path: "login", title: "Log In" },
      { path: "contact", title: "Contact" },
      { path: "about", title: "About" },
    ];

    return isLoggedIn ? loggedInPaths : loggedOutPaths;
  }

  const DropdownMenu = ({ paths }) => {
    return (
      <Menu
        id="navOptions"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        disableScrollLock // prevents header from resizing on mobile simulator when menu opened
      >
        {paths.map((pathData) => (
          <MenuItem
            key={pathData.path}
            onClick={() => onSelect(pathData.path)}
            // style={styles.menuItem}
          >
            <LabelView text={pathData.title} />
          </MenuItem>
        ))}
      </Menu>
    );
  };

  const mobileHeader = (
    <div style={styles.mobileHeaderContainer}>
      {hamburgerMenu}
      <div style={{ display: "flex" }}>
        {logoButton}
        {betaTag}
      </div>
      <img
        src={qrImage}
        alt="qr_code"
        style={{ height: 24 }}
        onClick={() => setIsQrCodeOpen(true)}
      />
      <DropdownMenu paths={getMobilePaths()} />
      {isQrCodeOpen && qrModal}
    </div>
  );

  return isMobile ? mobileHeader : header;
}

const styles = {
  headerContainer: {
    display: "flex",
    width: "100%",
    background: paletteRed,
    minHeight: 50,
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 24px",
  },
  mobileHeaderContainer: {
    display: "flex",
    width: "100%",
    background: paletteRed,
    minHeight: 50,
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 12px",
  },
  hamburgerContainer: {
    height: 20,
    width: 24,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  hamburgerLine: {
    height: 3,
    borderRadius: 2,
    background: whiteColor,
  },
  logoButton: {
    color: whiteColor,
    cursor: "pointer",
    marginTop: 2,
  },
};
